<template>
  <div style="background-color: #f6f6f6; font-size: 14px">
    <div class="background-wrapper">
      <div class="background"></div>
    </div>

    <!-- 菜单栏 -->
    <div class="lj-nav">
      <div class="lj-nav-left">
        <div class="lj-nav-item lj-nav-logo">
          <a class="lj-icon-compass lj-nav-item-title" @click="jumpPage('/')"
            >&nbsp;&nbsp;灯塔智能测评系统</a
          >
        </div>
        <div class="lj-nav-item">
          <a
            class="lj-nav-item-title hover-effect"
            @click="jumpPage('HomeIndexNewProductsManual')"
            >使用帮助</a
          >
        </div>
        <div class="lj-nav-item">
          <a
            class="lj-nav-item-title hover-effect"
            @click="jumpPage('ConsultUs')"
            >报告解读与生涯服务咨询</a
          >
        </div>
      </div>
      <!-- 登录后的账户 -->
      <div class="account" @mouseover="showMenu" @mouseout="hideMenu">
        <a
          class="account-title"
          :class="{
            'account-title-hover': !isMobile && isLoggedIn && menuDisplay
          }"
          @click="onMenuClick('Default')"
          @touchstart="toggleMenu"
        >
          <span
            class="lj-icon-mobile"
            v-show="!isLoggedIn"
            style="font-size: 1.4em; vertical-align: bottom"
          >
            &nbsp;
          </span>
          <span>{{ name }}</span>
          <span v-show="isLoggedIn">&nbsp;&nbsp;</span>
          <span
            class="lj-icon-caret-down account-title-caret-down"
            v-show="isLoggedIn"
            :class="{
              'account-title-caret-down-hover':
                !isMobile && isLoggedIn && menuDisplay
            }"
          ></span>
        </a>
        <div
          class="account-menu-wrapper"
          ref="menuWrapper"
          v-show="isLoggedIn && menuDisplay"
          @mouseover="showMenu"
          @mouseout="hideMenu"
        >
          <div class="account-menu" ref="menu">
            <a
              class="account-menu-item"
              @click="onMenuClick('subject')"
              @touchstart="onMenuClick('subject')"
              >学科能力测评</a
            >
            <a
              class="account-menu-item"
              @click="onMenuClick('major')"
              @touchstart="onMenuClick('major')"
              >智能专业匹配</a
            >
            <a
              class="account-menu-item"
              @click="onMenuClick('xuanke')"
              @touchstart="onMenuClick('xuanke')"
              >新高考选科</a
            >
            <a
              class="account-menu-item"
              @click="onMenuClick('consult')"
              @touchstart="onMenuClick('consult')"
              >报告解读与生涯服务咨询</a
            >
            <hr class="account-menu-hr" />
            <a
              class="account-menu-item"
              @click="onMenuClick('resetpwd')"
              @touchstart="onMenuClick('resetpwd')"
              >修改密码</a
            >
            <a
              class="account-menu-item"
              @click="LoginOut()"
              @touchend="LoginOutMobile()"
              >退出当前账户</a
            >
          </div>
        </div>
      </div>
    </div>

    <router-view
      @enterass="onTestEntryClick"
      style="background-color: #f6f6f6"
    />

    <!-- 登录弹出框 -->
    <div class="pop-wrapper" v-show="loginPopDisplay">
      <div class="login-bg" @click="onLoginPopClose"></div>
      <div class="login-wrapper" ref="loginWrapperDom">
        <div class="login">
          <div class="login-title">
            <span class="lj-icon-compass">灯塔智能生涯规划与数据分析系统</span>
          </div>
          <div class="lj-form login-form">
            <fieldset>
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="text"
                  name="number"
                  placeholder="请输入您的账号"
                  required=""
                  v-model="loginAccount"
                  oninput="(function(input){ input.value = input.value.trim() })(this)"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-user"></span>
                </div>
              </div>
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="password"
                  name="password"
                  placeholder="请输入您的密码"
                  required=""
                  v-model="loginPwd"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-key"></span>
                </div>
              </div>
              <p>
                <button
                  class="lj-btn lj-btn-primary lj-btn-block"
                  @click="LoginInSys"
                  style="margin-bottom: 1rem"
                >
                  登录
                </button>
                <button
                  class="lj-btn lj-btn-thrid lj-btn-block"
                  @click.prevent="onLoginPopClose"
                >
                  返回
                </button>
              </p>
            </fieldset>
          </div>
          <!-- <div class="register">
              <span>您还没有账号吗？先注册一个吧！</span>
              <a
                class="lj-btn lj-btn-secondary lj-btn-xs"
                @click="onRegisterClick"
              >
                快速注册
              </a>
            </div> -->
          <div class="register">
            <span>如果是机构用户，请转到机构专用登录页面</span
            ><a
              class="lj-btn lj-btn-third lj-btn-xs"
              href="https://career-explore.com/institution"
              >机构登陆</a
            >
          </div>
          <hr />
          <p style="margin: 0">欢迎使用灯塔智能生涯规划与数据分析系统</p>
          <p style="margin-bottom: 0">希望我们的智能分析结果能给您带来帮助！</p>
        </div>
      </div>
    </div>

    <!-- 注册弹出框 -->
    <div class="pop-wrapper" v-show="registerPopDisplay">
      <div class="login-bg" @click="onRegisterPopClose"></div>
      <div class="login-wrapper" ref="registerWrapperDom">
        <div class="login">
          <div class="login-title">
            <span class="lj-icon-compass"> 灯塔智能生涯规划与数据分析系统</span>
          </div>
          <form
            class="lj-form login-form"
            action="/Home/User/customer"
            method="post"
          >
            <fieldset>
              <div class="lj-form-group" style="display: none">
                <input
                  class="lj-radius"
                  type="text"
                  name="nickname"
                  placeholder="请输入您的昵称"
                  required=""
                  value="新用户"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-user"></span>
                </div>
              </div>
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="text"
                  name="phone"
                  v-model="registerPhone"
                  placeholder="请输入您的手机号"
                  required=""
                  oninput="(function(input){ input.value = input.value.trim() })(this)"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-user"></span>
                </div>
              </div>
              <div class="lj-input-group" style="margin-bottom: 1.5rem">
                <input
                  class="lj-form-field"
                  type="text"
                  name="sms"
                  v-model="registerSMS"
                  placeholder="请输入您收到的验证码"
                  required=""
                  oninput="(function(input){ input.value = input.value.trim() })(this)"
                /><span class="lj-input-group-btn"
                  ><button
                    class="lj-btn lj-btn-default"
                    type="button"
                    :class="{ 'lj-disabled': SMSClock }"
                    @click="onSMSClick"
                  >
                    {{ SMSClock ? '请等待' + SMSClock + '秒' : '发送验证码' }}
                  </button></span
                >
              </div>
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="password"
                  name="password"
                  placeholder="请输入您的密码"
                  required=""
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-key"></span>
                </div>
              </div>
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="password"
                  name="password_confirm"
                  placeholder="请再次输入您的密码"
                  required=""
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-key"></span>
                </div>
              </div>
              <p>
                <button
                  class="lj-btn lj-btn-primary lj-btn-block"
                  type="submit"
                  style="margin-bottom: 1rem"
                >
                  完成注册</button
                ><button
                  class="lj-btn lj-btn-thrid lj-btn-block"
                  @click.prevent="onRegisterPopClose"
                >
                  返回
                </button>
              </p>
            </fieldset>
          </form>
        </div>
      </div>
    </div>

    <div
      class="pop-wrapper"
      v-show="isLoggedIn && !InfoCompleted && infoPopDisplay"
    >
      <div class="login-bg" @click="onInfoPopClose"></div>
      <div class="login-wrapper">
        <div class="login">
          <p style="margin-bottom: 0.5rem">
            您好！欢迎使用灯塔智能生涯规划与数据分析系统！在开始使用前，您需要先完善一些信息。
          </p>
          <form class="lj-form query-form">
            <fieldset>
              <div class="lj-form-group">
                <label>学校</label
                ><input
                  class="lj-radius"
                  type="text"
                  :readonly="infoCollect.somereadonly"
                  name="school"
                  placeholder="请输入您的学校"
                  required=""
                  v-model="infoCollect.school.value"
                />
              </div>
              <div class="lj-form-group">
                <label>年级</label
                ><input
                  class="lj-radius"
                  type="text"
                  :readonly="infoCollect.somereadonly"
                  name="grade"
                  placeholder="请选择您的年级"
                  required=""
                  v-model="infoCollect.grade.value"
                />
              </div>
              <div class="lj-form-group">
                <label>姓名</label
                ><input
                  class="lj-radius"
                  type="text"
                  name="username"
                  placeholder="请输入您的姓名"
                  required=""
                  :readonly="infoCollect.somereadonly"
                  v-model="infoCollect.username.value"
                />
              </div>
              <div class="lj-form-group">
                <label>学号</label
                ><input
                  class="lj-radius"
                  type="text"
                  name="studentid"
                  placeholder="请输入您的学号"
                  required=""
                  :readonly="infoCollect.somereadonly"
                  v-model="infoCollect.studentid.value"
                />
              </div>
              <div class="lj-form-group">
                <label>性别</label>
                <div style="display: flex; align-items: center">
                  <div class="lj-radio">
                    <label
                      >男<input
                        type="radio"
                        name="gender"
                        value="男"
                        checked
                        v-model="infoCollect.gender.value"
                    /></label>
                  </div>
                  <div class="lj-radio">
                    <label
                      >女<input
                        type="radio"
                        name="gender"
                        value="女"
                        v-model="infoCollect.gender.value"
                    /></label>
                  </div>
                </div>
              </div>
              <div class="lj-form-group">
                <label>手机号</label
                ><input
                  class="lj-radius"
                  type="text"
                  name="phone"
                  placeholder="请输入您的手机号"
                  required=""
                  v-model="infoCollect.phone.value"
                />
              </div>
              <div class="button-wrapper">
                <button
                  class="lj-btn lj-btn-primary lj-btn-block"
                  @click.prevent="onInfoFinsih"
                >
                  填写完成
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="information">
        <!-- <span class="driver">本产品由灯塔教育专利智能专业匹配算法驱动</span> -->
        <!-- <span class="patent-number">（专利号：201810642977.1）</span> -->
        <span class="corporation">四川智辉山河教育科技有限责任公司</span
        ><a
          class="record"
          href="http://www.beian.miit.gov.cn"
          title="网站备案"
          target="_blank"
          >蜀ICP备16031008号-1</a
        >
        <!-- <p class="phone">联系电话：028-67873357-转分机号2</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import {
  login,
  loginout,
  infoquery,
  PersonalInfo
} from './../../../../api/userinfo'
import md5 from 'js-md5'

export default {
  name: '',
  data: function () {
    return {
      loading: false,

      loginAccount: null,
      loginPwd: null,

      infoCollect: {
        somereadonly: false,
        username: { value: '' },
        gender: { value: '' },
        school: { value: '' },
        grade: { value: '' },
        phone: { value: '' },
        studentid: { value: '' } //学号
      },

      loginPopDisplay: !1,
      registerPopDisplay: !1,
      infoPopDisplay: !1,

      bindCardPopDisplay: !1,
      menuDisplay: !1,
      registerPhone: '',
      registerSMS: '',
      bindCard: '',
      bindCardPassword: '',
      SMSClock: 0,

      isVIP: !1,
      hasPaid: !1
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    },
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    },
    name: function () {
      return this.isLoggedIn
        ? 'identity' === this.$store.state.User.identity
          ? '系统管理员'
          : this.$store.state.User.username || '新用户'
        : '登录/注册'
    },
    InfoCompleted: function () {
      return this.$store.state.User.infoCompleted
    },
    userStatus: function () {
      return 1
    }
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    onOverallIntroClick: function (i) {
      var e = 'features'
      i && (e = 'feature-' + i),
        window.scrollTo({
          behavior: 'smooth',
          top: document.getElementById(e).offsetTop
        })
    },
    onBusinessCooperationClick: function () {
      window.scrollTo({
        behavior: 'smooth',
        top: document.getElementById('products').offsetTop - 60
      })
    },
    onBindCardClick: function () {
      var n = this
      if (
        ((this.bindCard = this.bindCard.trim()),
        (this.bindCardPassword = this.bindCardPassword.trim()),
        this.bindCard && this.bindCardPassword)
      ) {
        var i = this.$store.state.Url.app + '/Home/Index/bind_card',
          e = { card: this.bindCard, card_psw: this.bindCardPassword }
        $.post(i, e).then(function (i) {
          'string' == typeof i && (i = JSON.parse(i))
          var e = Number(i.code),
            t = i.msg
          1 === e
            ? (window.location.href = n.$url.app + '/Home/Major/index')
            : n.$message.error(t)
        })
      } else
        this.$message.error('抱歉，卡号或密码不能为空，请输入正确的卡号和密码')
    },
    onSMSClick: function () {
      var s = this
      if (0 === this.SMSClock) {
        // var i = this.$store.state.Url.app + '/Home/Sms/sms/phone/' + this.registerPhone
        var i =
          this.$store.state.Url.app +
          '/Home/Sms/sms/phone/' +
          this.registerPhone
        $.get(i).then(function (i) {
          'string' == typeof i && (i = JSON.parse(i))
          var e = i.res,
            t = e.Code,
            n = e.Message
          if ('OK' === t && 'OK' === n) {
            s.$message({
              message: '验证码发送成功，请注意查收',
              type: 'success'
            }),
              (s.SMSClock = 120)
            !(function i () {
              setTimeout(function () {
                0 < s.SMSClock && (s.SMSClock--, i())
              }, 1e3)
            })()
          } else s.$notify.error({ title: t, message: n })
        })
      } else this.$message.error('请不要频繁发送验证码！')
    },
    toggleMenu: function () {
      this.menuDisplay = !this.menuDisplay
    },
    showMenu: function () {
      !this.isMobile && this.isLoggedIn && (this.menuDisplay = !0)
    },
    hideMenu: function () {
      this.menuDisplay = !1
    },
    onLoginClick: function () {
      this.loginPopDisplay = !0
    },
    onLoginPopClose: function () {
      this.loginPopDisplay = !1
    },
    onBindCardPopClick: function () {
      this.bindCardPopDisplay = !0
    },
    onBindCardPopClose: function () {
      this.bindCardPopDisplay = !1
    },
    onRegisterClick: function () {
      this.onLoginPopClose(), (this.registerPopDisplay = !0)
    },
    onRegisterPopClose: function () {
      this.registerPopDisplay = !1
    },
    onInfoPopClose: function () {
      this.infoPopDisplay = !1
    },
    onInfoPopClick: function () {
      this.$message(
        '您好！欢迎使用灯塔智能生涯规划与数据分析系统！在开始使用前，您需要先完善一些信息。'
      )
      infoquery({}).then(res => {
        if (res.rescode == 200) {
          this.infoCollect.username.value = res.username
          this.infoCollect.gender.value = res.gender
          this.infoCollect.school.value = res.schoolname
          this.infoCollect.grade.value =
            res.enrollyear == 0 ? '' : res.enrollyear
          this.infoCollect.phone.value = res.phonenumber
          this.infoCollect.studentid.value = res.studentidnum
          this.infoPopDisplay = 1
          if (this.infoCollect.school.value != '') {
            this.infoCollect.somereadonly = true
          }
        }
      })
    },
    onMenuClick: function (i) {
      if (i == 'major' || i == 'subject' || i == 'xuanke') {
        // if (this.isLoggedIn) {
        //   this.onTestEntryClick(i)
        // } else {
        //   this.onLoginClick()
        // }

        this.onTestEntryClick(i)
      } else if (i == 'Default') {
        // this.onLoginClick()
      } else if (i == 'consult') {
        this.jumpPage('ConsultUs')
      } else if (i == 'resetpwd') {
        this.jumpPage('resetpwd')
      }
    },

    /**
     * 登录、登出
     */
    LoginInSys: function () {
      login({
        account: this.loginAccount,
        password: md5(this.loginPwd)
      })
        .then(res => {
          console.log('axios res', res)
          if (res.rescode == 400) {
            this.$message.error('账号或者密码错误')
          } else if (res.rescode == 200 || res.rescode == 201) {
            this.$message.success('登录成功')
            let identity = res.identity
            this.$store.commit('User/LoginIn', [
              this.loginAccount,
              identity,
              res.username
            ])
            if (identity == 'student') {
              this.$store.commit('User/ChangeInfo', [res.InfoCompleted, null])

              if (!this.InfoCompleted) {
                this.onInfoPopClick()
              }
            } else if (identity == 'school') {
              this.$router.push({ path: '/statistic' })
            }
          }
          this.onLoginPopClose()
        })
        .catch(err => {})
    },
    LoginOutMobile:function(){
      loginout({}).then(res => {
            if (res.rescode == 200) {
              this.$store.commit('User/LoginOut')
              this.$router.replace({ path: '/login' })
              this.$message({
                type: 'success',
                message: '已退出登录!'
              })
            }
          })
    },
    LoginOut: function () {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 清除一下session
          loginout({}).then(res => {
            if (res.rescode == 200) {
              this.$store.commit('User/LoginOut')
              this.$router.replace({ path: '/login' })
              this.$message({
                type: 'success',
                message: '已退出登录!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },

    /**
     * 填写完所有的信息
     */
    onInfoFinsih: function () {
      if (
        this.infoCollect.username.value == '' ||
        this.infoCollect.gender.value == '' ||
        this.infoCollect.school.value == '' ||
        this.infoCollect.grade.value == '' ||
        this.infoCollect.phone.value == '' ||
        this.infoCollect.studentid.value == ''
      ) {
        this.$message.error('请将信息填写完整')
      } else {
        // axios发送信息
        PersonalInfo({
          username: this.infoCollect.username.value,
          gender: this.infoCollect.gender.value,
          schoolname: this.infoCollect.school.value,
          enrollyear: this.infoCollect.grade.value,
          phonenumber: this.infoCollect.phone.value,
          studentidnum: this.infoCollect.studentid.value
        }).then(res => {
          if (res.rescode == 200) {
            this.$message('信息保存成功')
            this.$store.commit('User/ChangeInfo', [
              1,
              this.infoCollect.username.value
            ])
            this.onInfoPopClose()
          }
        })
      }
    },
    /**
     * @param {三个模块，具体跳转到哪儿} dest
     */
    onTestEntryClick: function (dest) {
      // if (this.isLoggedIn && this.InfoCompleted) {
      //   if (dest == 'major') {
      //     this.jumpPage('HomeMajorStdMatchPage')
      //   } else if (dest == 'subject') {
      //     this.jumpPage('HomeTestIndex')
      //   } else if (dest == 'xuanke') {
      //     this.jumpPage('monixuanke')
      //   }
      // } else {
      //   this.onInfoPopClick()
      // }

      if (dest == 'major') {
          this.jumpPage('HomeMajorStdMatchPage')
        } else if (dest == 'subject') {
          this.jumpPage('HomeTestIndex')
        } else if (dest == 'xuanke') {
          this.jumpPage('monixuanke')
        }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../../Public/resources/css/templates/home/index/index.min.css');
@import url('./../../../../Public/resources/css/loading.css');
@import url('./../../../../Public/utils/element-ui/index.css');
* {
  text-align: left;
}

.lj-nav-left {
  float: left;
}

.lj-form-group {
  margin-bottom: 0.5rem;
  align-items: center;
}

.lj-radio {
  margin: 0;
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
}

.lj-checkbox + .lj-checkbox,
.lj-radio + .lj-radio {
  margin-top: 0;
}

.pop-wrapper {
  padding-top: 3%;
}

.phone {
  margin: 0 0 0.5rem;
  text-align: center;
  color: #000;
}
</style>
